var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{ref:"target",staticClass:"input-select group relative block w-full text-md text-slate",class:[
    _setup.isMenuOpen ? 'z-50' : 'z-[1]',
    _vm.disabled && 'pointer-events-none opacity-50',
  ],on:{"click":function($event){$event.preventDefault();return _setup.toggleMenu()}}},[_vm._t("icon",function(){return [(_vm.icon)?_c('UiIcon',{staticClass:"input-select__icon z-[2] shrink-0",attrs:{"icon":_vm.icon}}):_vm._e()]}),_vm._v(" "),(_vm.label)?_c('span',{staticClass:"absolute -top-1.5 left-3.5 z-10 whitespace-nowrap bg-white px-1"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),(!_vm.inputFitWidth)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.valueLocal),expression:"valueLocal"}],staticClass:"input-select__input relative z-[2] h-16 w-full cursor-pointer border border-gray-lighten1 px-6 text-md transition placeholder:text-slate-lighten4 focus:outline-0 focus:outline-offset-0 focus:ring-0 group-hover:border-gray",class:[
      { '!border-red-lighten1': _vm.error },
      _setup.isMenuOpen ? 'rounded-t-lg' : 'rounded-lg',
    ],attrs:{"readonly":"","autocomplete":"off","placeholder":_vm.placeholder},domProps:{"value":(_setup.valueLocal)},on:{"input":function($event){if($event.target.composing)return;_setup.valueLocal=$event.target.value}}}):_c('span',{staticClass:"input-select__input relative z-[2] h-16 w-full cursor-pointer border border-gray-lighten1 px-6 text-md transition placeholder:text-slate-lighten4 focus:outline-0 focus:outline-offset-0 focus:ring-0 group-hover:border-gray",class:[
      { '!border-red-lighten1': _vm.error },
      _setup.isMenuOpen ? 'rounded-t-lg' : 'rounded-lg',
    ]},[_vm._v("\n    "+_vm._s(_setup.valueLocal)+"\n  ")]),_vm._v(" "),_c('button',{staticClass:"input-select__toggle absolute right-9 top-1/2 z-[2] -translate-y-1/2",class:{ 'z-[2]': _setup.isMenuOpen },attrs:{"type":"button","aria-label":"Open menu"}},[_c('UiIcon',{staticClass:"h-4 w-4",attrs:{"icon":_setup.isMenuOpen ? 'chevronUp' : 'chevronDown'}})],1),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":"transform duration-300","enter-class":"opacity-0 translate-y-4","enter-to-class":"opacity-100 translate-y-0","leave-active-class":"transform duration-300","leave-class":"opacity-100 translate-y-0","leave-to-class":"opacity-0 translate-y-4"}},[(_setup.isMenuOpen)?_c('div',{staticClass:"input-select__menu absolute left-0 right-0 top-0 z-[1] overflow-hidden rounded-lg border-[1px] border-t-0 border-gray bg-white pt-20 shadow-default"},[_c('div',{staticClass:"max-h-[312px] overflow-y-auto bg-white scrollbar scrollbar-track-transparent scrollbar-thumb-blue"},[(!_vm.customOptions)?_vm._l((_vm.options),function(option){return _c('div',{key:option[_vm.itemId],staticClass:"flex min-h-[3rem] cursor-pointer items-center px-6 py-[0.594rem]",class:[
              _setup.activeOption && option[_vm.itemId] === _setup.activeOption[_vm.itemId]
                ? 'bg-slate text-white'
                : 'hover:bg-gray-lighten3',
            ],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _setup.selectOption(option)}}},[_vm._v("\n            "+_vm._s(option[_vm.itemText])+"\n          ")])}):_vm._e(),_vm._v(" "),_vm._t("default")],2)]):_vm._e()]),_vm._v(" "),(_vm.error)?_c('span',{staticClass:"block py-1 text-red-lighten1"},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }