import { render, staticRenderFns } from "./UiTag.vue?vue&type=template&id=0011cf19"
import script from "./UiTag.vue?vue&type=script&setup=true&lang=ts"
export * from "./UiTag.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports