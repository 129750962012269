var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_vm.to ? 'ui-link' : 'button',{tag:"component",staticClass:"inline-flex",class:[
    _vm.block ? 'w-full' : 'w-auto',
    _vm.disabled ? 'cursor-not-allowed opacity-40' : '',
    _vm.absolute ? 'absolute' : 'relative',
  ],attrs:{"type":_vm.type,"to":_vm.to,"disabled":_vm.disabled,"target":_vm.target},on:{"click":_setup.clickEvent}},[_c('span',{staticClass:"title relative flex items-center justify-center text-sm uppercase leading-none tracking-[.12em] transition",class:[
      { 'w-full': _vm.block },
      { '!h-full max-h-full': _vm.fitHeight },
      { 'border-[1px]': _vm.outlined },
      { 'rounded-full': _vm.rounded },
      _setup.currentVariant,
      _vm.innerWrapperClass,
    ],style:({ minWidth: _vm.minWidth })},[_vm._t("default"),_vm._v(" "),(_vm.icon)?_c('UiIcon',{staticClass:"ml-2.5 h-5 w-5",attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',{staticClass:"spinner ml-2.5 inline-block h-5 w-5"}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }