import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=175599c2"
import script from "./AppFooter.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppFooter.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SocialsList: require('/apps/jenkins/workspace/WEBPLATFORM/FRONTEND/PRESTIGIO/PRESTIGIO_Deploy_Prod/components/SocialsList.vue').default})
