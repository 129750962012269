/* eslint-disable no-nested-ternary */
import { sectionsWithDataProps } from '@webplatform/asbis-ui/dist/sections';
import { kebabToPascal } from '.';

type BlockType = 'asbis-ui' | 'json' | 'textarea' | 'html';
interface Block {
  content: {
    component: string | null;
    data: any;
    type: string;
  };
  type: BlockType;
}

// Тип блоков секций из catalog/catalog-categories
interface CatalogBlock {
  content_type: BlockType;
  data:
    | {
        component: string | null;
        data: any;
        type: string;
      }
    | any;
  type: string;
}

export interface PreparedComponent {
  type: string;
  data: any;
}

interface PrepareDynamicBlockOptions {
  excludedBlocks?: string[];
  aliases?: Record<string, string>;
  noDataPropsSections?: string[];
}

function getItemsData(data?: any[] | Record<string, any> | null) {
  if (data === undefined || data === null) return {};
  return Array.isArray(data) ? { items: data } : data;
}
function getComponentTypeFilteredByAliases(
  type: string,
  componentsAliases: Record<string, string> = {},
): string {
  return componentsAliases[type] ?? type;
}
function isComponentsWithDataProps(
  name?: string,
  excludeNamesFromCheck: string[] = [],
) {
  return name && !excludeNamesFromCheck.includes(name)
    ? sectionsWithDataProps.includes(kebabToPascal(name))
    : false;
}
function getComponentBindingDataByNameAndBlockData(
  isComponentWithDataProps: boolean,
  data: any[] | Record<string, any>,
) {
  return !isComponentWithDataProps
    ? getItemsData(data)
    : { data: getItemsData(data) };
}
function getBlockTypeAndBlockDataByBlock(block: Block | CatalogBlock) {
  const isAsbisUiFromCatalog =
    'content_type' in block && block.content_type === 'asbis-ui';

  const blockType =
    'content' in block
      ? block.type === 'textarea'
        ? block.type
        : block.content.type
      : block.type;
  const blockData =
    'content' in block
      ? block.content.data
      : isAsbisUiFromCatalog
      ? block.data.data
      : block.data;

  return {
    blockType,
    blockData,
  };
}

function getComponentByBlock(
  blockInfo: { originalBlock: Block | CatalogBlock } & PreparedComponent,
) {
  const { originalBlock, data, type } = blockInfo;

  const isBlockTextarea = type === 'textarea';
  const isBlockFromCatalog = 'content' in originalBlock;

  const component = isBlockTextarea
    ? {
        type: 'html-content-block',
        data:
          'content' in originalBlock
            ? originalBlock.content
            : originalBlock.data,
      }
    : {
        ...(isBlockFromCatalog && originalBlock.content),
        type,
        data,
      };

  return component;
}

function cmsBlockToComponentSerializer(
  block: CatalogBlock | Block,
  options?: PrepareDynamicBlockOptions,
): PreparedComponent | object {
  const excludedBlocks = options?.excludedBlocks ?? [];
  const aliases = options?.aliases ?? {};
  const noDataPropsSections = options?.noDataPropsSections ?? [];

  const { blockType, blockData } = getBlockTypeAndBlockDataByBlock(block);
  const type = getComponentTypeFilteredByAliases(blockType, aliases);
  const isComponentWithDataProps = isComponentsWithDataProps(
    blockType,
    noDataPropsSections,
  );
  const data = getComponentBindingDataByNameAndBlockData(
    isComponentWithDataProps,
    blockData,
  );

  const component = getComponentByBlock({
    originalBlock: block,
    type,
    data,
  });

  const result = !excludedBlocks?.includes(component?.type) ? component : {};

  return result;
}

export default cmsBlockToComponentSerializer;
