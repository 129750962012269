import { render, staticRenderFns } from "./ProductFootnote.vue?vue&type=template&id=66c7787c&scoped=true"
import script from "./ProductFootnote.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductFootnote.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProductFootnote.vue?vue&type=style&index=0&id=66c7787c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../work/modules/node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c7787c",
  null
  
)

export default component.exports