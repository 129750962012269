
import {
  reactive,
  ref,
  useStore,
  useContext,
  watch,
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';
import {
  useDPD,
  useModalDialog,
  useCountries,
  useForm,
  useFetchCategory,
} from '~/composables';

interface ConsultationForm {
  type?: string;
  site: string;
  page_url?: string;
  lang: string;
  name: string;
  email: string;
  phone: string;
  category?: string;
  sn: string;
  model?: number;
  purchaseDate: string;
  deliveryMethod?: 'courier' | 'pickup';
  country: string;
  buildingNumber: string;
  city: string;
  apartmentNumber: string;
  street: string;
  postalCode: string;
  faultDescription: string;
  policy: boolean;
  personalData: boolean;
  fullAddress: string;
}

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const config = store.getters['config/getConfig'];
    const lang: string = store.getters['config/getActiveLanguage'];
    const form = ref<HTMLFormElement>();
    const { submit, formPending, recaptcha, isSubmitDisabled } = useForm({
      confirm: false,
    });
    const { $gtm, nuxtState } = useContext();
    const {
      getDpdCountries,
      dpdCountries,
      getDpdCities,
      dpdCities,
      isDpdCitiesLoading,
    } = useDPD();
    const {
      isOpen: infoModalIsOpen,
      open: openInfoModal,
      close: closeInfoModal,
    } = useModalDialog('info');
    const { getCountriesList } = useCountries();
    const { category, fetchCategory } = useFetchCategory();

    const formData = reactive<ConsultationForm>({
      site: config.domain,
      lang,
      name: '',
      email: '',
      phone: '',
      category: '',
      sn: '',
      model: undefined,
      purchaseDate: '',
      deliveryMethod: undefined,
      country: '',
      buildingNumber: '',
      city: '',
      apartmentNumber: '',
      street: '',
      postalCode: '',
      faultDescription: '',
      policy: false,
      personalData: false,
      fullAddress: '',
    });

    const clearForm = () => {
      Object.assign(formData, {
        site: config.domain,
        lang,
        name: '',
        email: '',
        phone: '',
        category: '',
        sn: '',
        model: undefined,
        purchaseDate: '',
        deliveryMethod: undefined,
        country: '',
        buildingNumber: '',
        city: '',
        apartmentNumber: '',
        street: '',
        postalCode: '',
        faultDescription: '',
        policy: false,
        personalData: false,
        fullAddress: '',
      });
      form.value?.reset();
    };
    const isFormStartEventSended = ref(false);
    const categories = [
      { id: 'tablet-pc', name: 'Tablets' },
      { id: 'video-conferencing-systems', name: 'Video Conferencing Systems' },
    ];
    const models = computed(() => {
      return category.value?.products?.map((product) => ({
        id: product.id,
        name: product.name,
      }));
    });
    const deliveryMethods = [
      {
        id: 'courier',
        name: 'Courier delivery',
      },
      {
        id: 'pickup',
        name: 'Pick up point',
      },
    ];
    const neededCountriesCodes = [
      'AT',
      'BE',
      'HR',
      'CZ',
      'EE',
      'FR',
      'DE',
      'HU',
      'LT',
      'LV',
      'NL',
      'PL',
      'PT',
      'SK',
      'SI',
      'ES',
      'DK',
    ];
    const countries = getCountriesList().filter((country) =>
      neededCountriesCodes.includes(country.code),
    );
    const searchedAddress = ref('');
    const addressSuggestions = ref<any[] | null>(null);
    const isDpdAdressesDisabled = ref(false);

    const handleSelectCategory = async (categoryName: string) => {
      await fetchCategory(categoryName, { limit: 10 });
    };

    const handleSelectCountry = async (countryName: string) => {
      const selectedCountryId = dpdCountries.value.find(
        (dpdCountry) => dpdCountry.name === countryName,
      ).id;
      const citiesData = await getDpdCities(selectedCountryId);
      addressSuggestions.value = dpdCities.value;
      isDpdAdressesDisabled.value = citiesData.code === 1001;

      formData.city = '';
      formData.fullAddress = '';
      searchedAddress.value = '';
    };

    const searchAddress = (event: any) => {
      searchedAddress.value = event.query;
      // if (!searchedAddress.value) addressSuggestions.value = dpdAddresses.value;
      if (!searchedAddress.value) {
        addressSuggestions.value = dpdCities.value;
        return;
      }
      const suggestions = dpdCities.value.filter((dpdAddress: any) => {
        const fullAddress = `${dpdAddress.city}, ${dpdAddress.postcode}, ${dpdAddress.street} ${dpdAddress.house_number}`;
        return fullAddress
          .toLowerCase()
          .includes(searchedAddress.value.toLowerCase().trim());
      });
      addressSuggestions.value = suggestions;
    };
    const handleSelectAddress = (event: any) => {
      const fullAddress = `${event.value.city}, ${event.value.postcode}, ${event.value.street} ${event.value.house_number}`;
      searchedAddress.value = fullAddress;
      formData.fullAddress = fullAddress;
      formData.city = event.value.city;
    };
    const handleBlurAddress = () => {
      if (searchedAddress.value && !formData.fullAddress)
        searchedAddress.value = formData.fullAddress;
    };

    const pushFormStartEvent = () => {
      if (!isFormStartEventSended.value) {
        $gtm.push({
          event: 'form_start',
        });
      }
      isFormStartEventSended.value = true;
    };
    const clearAdressLines = () => {
      formData.country = '';
      formData.city = '';
      formData.buildingNumber = '';
      formData.apartmentNumber = '';
      formData.street = '';
      formData.postalCode = '';
      formData.fullAddress = '';
    };

    const submitForm = async () => {
      try {
        const formId = nuxtState.config.serviceFormUuid;
        const resultFormData = {
          ...formData,
          page_url: window.location.href,
        };

        await submit(resultFormData, formId);

        $gtm.push({
          event: 'leadform_sent',
        });
        openInfoModal();
        clearForm();
      } catch (error) {
        console.error('Error submitting form', error);
      }
    };

    watch(formData, () => {
      pushFormStartEvent();
    });
    watch(
      () => formData.deliveryMethod,
      async (newVal) => {
        clearAdressLines();
        if (newVal === 'pickup' && !dpdCountries.value.length) {
          await getDpdCountries();
        }
      },
    );

    return {
      form,
      formData,
      categories,
      models,
      deliveryMethods,
      countries,
      dpdCountries,
      formPending,
      submitForm,
      recaptcha,
      infoModalIsOpen,
      closeInfoModal,
      handleSelectCountry,
      addressSuggestions,
      isDpdAdressesDisabled,
      searchedAddress,
      isDpdCitiesLoading,
      searchAddress,
      handleSelectAddress,
      handleBlurAddress,
      handleSelectCategory,
      isSubmitDisabled,
    };
  },
});
