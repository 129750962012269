export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: true,
  locales: [{"code":"ru","name":"ru","iso":"ru","file":"getTranslations.js"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ru","name":"ru","iso":"ru","file":"getTranslations.js"}],
  localeCodes: ["ru"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "g",
  3: "e",
  4: "t",
  5: "T",
  6: "r",
  7: "a",
  8: "n",
  9: "s",
  10: "l",
  11: "a",
  12: "t",
  13: "i",
  14: "o",
  15: "n",
  16: "s",
  17: ".",
  18: "j",
  19: "s",
  20: "\"",
  21: ":",
  22: "\"",
  23: ".",
  24: ".",
  25: "/",
  26: "l",
  27: "a",
  28: "n",
  29: "g",
  30: "/",
  31: "g",
  32: "e",
  33: "t",
  34: "T",
  35: "r",
  36: "a",
  37: "n",
  38: "s",
  39: "l",
  40: "a",
  41: "t",
  42: "i",
  43: "o",
  44: "n",
  45: "s",
  46: ".",
  47: "j",
  48: "s",
  49: "\"",
  50: "}",
}

export const localeMessages = {
  'getTranslations.js': () => import('../../lang/getTranslations.js' /* webpackChunkName: "lang-getTranslations.js" */),
}
