var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{staticClass:"group relative block w-full max-w-full text-slate"},[(_vm.label)?_c('span',{staticClass:"absolute -top-1.5 left-3.5 z-10 whitespace-nowrap bg-white px-1 text-md group-hover:text-gray"},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]):_vm._e(),_vm._v(" "),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.valueLocal),expression:"valueLocal"}],ref:"input",staticClass:"h-16 w-full border-gray-lighten1 px-6 text-md transition placeholder:text-slate-lighten4 focus:border-slate-lighten4 focus:outline-0 focus:outline-offset-0 focus:ring-0 group-hover:border-gray",class:[
      { '!border-red-lighten1': _vm.error },
      { '!border-gray-lighten1 !text-slate-lighten4': _vm.disabled },
      _vm.rounded,
    ],attrs:{"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_setup.valueLocal)?_vm._i(_setup.valueLocal,null)>-1:(_setup.valueLocal)},on:{"change":function($event){var $$a=_setup.valueLocal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_setup.valueLocal=$$a.concat([$$v]))}else{$$i>-1&&(_setup.valueLocal=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_setup.valueLocal=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.valueLocal),expression:"valueLocal"}],ref:"input",staticClass:"h-16 w-full border-gray-lighten1 px-6 text-md transition placeholder:text-slate-lighten4 focus:border-slate-lighten4 focus:outline-0 focus:outline-offset-0 focus:ring-0 group-hover:border-gray",class:[
      { '!border-red-lighten1': _vm.error },
      { '!border-gray-lighten1 !text-slate-lighten4': _vm.disabled },
      _vm.rounded,
    ],attrs:{"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_setup.valueLocal,null)},on:{"change":function($event){_setup.valueLocal=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_setup.valueLocal),expression:"valueLocal"}],ref:"input",staticClass:"h-16 w-full border-gray-lighten1 px-6 text-md transition placeholder:text-slate-lighten4 focus:border-slate-lighten4 focus:outline-0 focus:outline-offset-0 focus:ring-0 group-hover:border-gray",class:[
      { '!border-red-lighten1': _vm.error },
      { '!border-gray-lighten1 !text-slate-lighten4': _vm.disabled },
      _vm.rounded,
    ],attrs:{"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_setup.valueLocal)},on:{"input":function($event){if($event.target.composing)return;_setup.valueLocal=$event.target.value}}}),_vm._v(" "),(_vm.error)?_c('span',{staticClass:"block py-1 text-red-lighten1"},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }