var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative flex items-center sm:min-h-[500px] md:py-20 lg:min-h-[640px] 3xl:min-h-[800px]",class:{ 'bg-white': !_vm.data.dark }},[_c('div',{staticClass:"container flex w-full flex-col md:flex-row"},[(_setup.backgroundImage)?_c('div',{directives:[{name:"lazy-background",rawName:"v-lazy-background"}],staticClass:"-mx-6 aspect-[375/327] max-h-[400px] min-w-full bg-cover bg-center md:absolute md:inset-0 md:mx-0 md:aspect-auto md:max-h-full",attrs:{"lazy-background":_setup.backgroundImage}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"relative z-10 py-6 text-slate md:ml-[5%] md:mr-auto md:w-[440px] md:p-0 lg:ml-28 3xl:ml-[152px] 3xl:w-[600px]",class:{ 'text-slate md:text-white': _vm.data.dark }},[_c('UiSectionTitle',{staticClass:"title text-3xl uppercase leading-none md:text-5xl xl:text-6xl",attrs:{"title":_vm.product.name,"tag":"h1"}}),_vm._v(" "),(_vm.data.description)?_c('div',{staticClass:"mt-4 text-md leading-[1.8rem] xl:text-md",domProps:{"innerHTML":_vm._s(_vm.data.description)}}):_vm._e(),_vm._v(" "),(_setup.similarProducts)?_c('div',{staticClass:"title mt-4 flex flex-wrap gap-4 text-lg uppercase"},_vm._l((_setup.similarProducts),function(similarProduct,i){return _c('UiLink',{key:i,staticClass:"flex h-12 w-[69px] items-center justify-center rounded-lg bg-gray-lighten3 text-slate",class:[
            { 'md:bg-white md:bg-opacity-10 md:text-white': _vm.data.dark },
            {
              [_vm.data.dark
                ? '!bg-slate !text-white md:!bg-white md:bg-opacity-100 md:!text-slate'
                : '!bg-slate !text-white']: similarProduct.selected,
            },
          ],attrs:{"to":similarProduct.link},domProps:{"innerHTML":_vm._s(similarProduct.feature_value)}})}),1):_vm._e(),_vm._v(" "),(_vm.data.chips)?_c('div',{staticClass:"title mt-8 flex flex-wrap gap-2 text-xs uppercase leading-none md:mt-10"},_vm._l((_vm.data.chips),function(chip,i){return _c('div',{key:i,staticClass:"flex h-6 items-center justify-center whitespace-nowrap rounded-md border-[1px] border-gray px-2 tracking-[0.12em]",domProps:{"innerHTML":_vm._s(chip)}})}),0):_vm._e(),_vm._v(" "),(
          _vm.data.link &&
          Object.keys(_vm.data.link).length !== 0 &&
          Object.values(_vm.data.link).every((value) => value)
        )?_c('div',{staticClass:"mt-4"},[_c('UiLink',{staticClass:"title flex items-center text-sm uppercase leading-none tracking-[0.12em] text-blue",class:_vm.data.dark ? 'md:text-white' : 'md:text-slate',attrs:{"to":_vm.data.link.src}},[_c('span',{staticClass:"mr-2",domProps:{"innerHTML":_vm._s(_vm.data.link.text)}}),_vm._v(" "),_c('span',{staticClass:"h-4 w-4"},[_c('UiIcon',{attrs:{"icon":"arrowRightBold","container-size":""}})],1)])],1):_vm._e(),_vm._v(" "),(_vm.data.button && _vm.data.button.link)?_c('div',{staticClass:"mt-8 flex justify-center sm:justify-start md:mt-10"},[_c('UiButton',{attrs:{"min-width":!_setup.isBiggerSm ? '327px' : '228px',"size":"md","to":_vm.data.button.link,"target":_vm.data.button.target}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.button.text)}})])],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }