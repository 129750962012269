var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"autocomplete"},[_c('div',{staticClass:"autocomplete__wrapper w-full"},[(!_vm.loading)?_c('UiIcon',{staticClass:"autocomplete__icon",attrs:{"icon":"search"}}):_c('UiSpinnerCircle',{staticClass:"autocomplete__loader"}),_vm._v(" "),(!_setup.valueLocal)?_c('span',{staticClass:"autocomplete__placeholder pointer-events-none"},[_vm._v("\n      "+_vm._s(_vm.placeholder)+"\n    ")]):_vm._e(),_vm._v(" "),_c('AutoComplete',_vm._b({ref:"autocomplete",staticClass:"w-full",class:[
        {
          'pointer-events-none cursor-not-allowed opacity-60':
            _vm.disabled || _vm.loading,
          disabled: _vm.disabled || _vm.loading,
        },
      ],on:{"click":() => {
          _vm.$emit('click');
          // autocomplete ? autocomplete.showOverlay() : null;
        },"complete":function($event){return _vm.$emit('complete', $event)},"item-select":function($event){return _vm.$emit('item-select', $event)},"focus":() => {
          _vm.$emit('focus');
        },"blur":() => {
          _vm.$emit('blur');
        }},scopedSlots:_vm._u([{key:"item",fn:function(slotProps){return [_vm._t("acItem",null,{"item":slotProps.item})]}}],null,true),model:{value:(_setup.valueLocal),callback:function ($$v) {_setup.valueLocal=$$v},expression:"valueLocal"}},'AutoComplete',_vm.$attrs,false))],1),_vm._v(" "),(!_vm.loading && _vm.$attrs.suggestions && _vm.$attrs.suggestions.length)?_c('div',{staticClass:"autocomplete__count mt-4"},[_vm._v("\n    "+_vm._s(_vm.$t('found'))+" "),_c('span',[_vm._v(_vm._s(_vm.$attrs.suggestions.length))]),_vm._v("\n    "+_vm._s(_vm.countType)+"\n  ")]):(
      !_vm.loading &&
      _vm.$attrs.suggestions !== null &&
      (!_vm.$attrs.suggestions || !_vm.$attrs.suggestions.length)
    )?_c('div',{staticClass:"autocomplete__count mt-4"},[_vm._v("\n    "+_vm._s(_vm.$t('nothing-found'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.errors.length)?_c('span',{staticClass:"block py-1 text-red-lighten1"},[_vm._v("\n    "+_vm._s(_vm.errors[0])+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }