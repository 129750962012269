import {
  getProductDescription,
  getProductImage,
  getProductLink,
  getProductTags,
  handleImageAlt,
  handleImageSrc,
  isTouchDevice,
  kebabToPascal,
  pascalToKebab,
} from '@webplatform/frontkit/dist/helpers';

export {
  getProductDescription,
  getProductImage,
  getProductLink,
  getProductTags,
  handleImageAlt,
  handleImageSrc,
  isTouchDevice,
  kebabToPascal,
  pascalToKebab,
};
